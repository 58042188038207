<template>
    <router-link class="c-btn" :to="comp.settings.url">{{ comp.settings.text }}</router-link>
</template>

<script>
  export default {
    name: 'Comp_button',
    props: {
      comp: Object
    }
  }
</script>

<style scoped>

</style>
